/* 공통 스타일 */
.container {
  display: flex;
  justify-content: space-between;
  padding: 0 0px; /* 양쪽에 여백 추가 */
  max-width: 1200px; /* 최대 너비 설정 */
  margin: 0 auto; /* 가운데 정렬 */
  width: 100%; /* 화면 크기에 맞춰 유연하게 변경 */
  color: #888;
}

.box {
  background-color: lightgray;
  padding: 20px;
  border-radius: 5px;
}

.nav-link {
  text-decoration: none;
  color: #888;
  margin-left: 10px;
  border-bottom: 1px solid transparent;
}

.nav-link:hover {
  color: lightseagreen;
  border-bottom: 1px solid lightseagreen;
}

.content_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color:#888;
}

.sentence_margin_bottom {
  margin-bottom: 5px;
}

.text-container {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 20px;
  color: #888;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}